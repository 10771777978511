<template>
    <!-- 摄影师详情路由 -->
    <div class="dresser-detail-box">
        <member-detail-banner></member-detail-banner>
        <member-detail-info
            service-router="dresserService">
        </member-detail-info>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
    components: {
        memberDetailBanner: () => import("@/components/memberDetailBanner"),
        memberDetailInfo: () => import("@/components/memberDetailInfo"),
    },
    methods: {
        ...mapMutations([
            'setPersonalData',
            'setMemberServiceList'
        ]),
        ...mapActions([
            "getDresserDetail",
            'getMemberServiceList'
        ]),
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next(vm => {
            vm.getDresserDetail(to.query.id);
            vm.getMemberServiceList(to.query.id);
        });
    },
    beforeRouteLeave (to, from, next) {
        this.setPersonalData({});
        this.setMemberServiceList({});
        next();
    },
}
</script>

<style>
</style>
